import Vue from 'vue'
import VueRouter from 'vue-router'

import index from '@/views/index.vue'
import Home from '@/views/Home.vue'
import Send from '@/views/Send.vue'
import SupplierRegistration from '@/views/SupplierRegistration.vue'

import VueBodyClass from 'vue-body-class';

Vue.use(VueRouter)

const routes = [
    {
      path: '/',
      name: 'index',
      component: index,
      children:[
        {
          path:'home',
          name: 'Home',
          meta: { bodyClass: 'home' },
          component:Home
        },
        {
            path:'send',
            name: 'Send',
            meta: { bodyClass: 'send' },
            component:Send,
            props: true
        }
      ]
    },
    {
      path: '/supplier-registration',
      name: 'supplier-registration',
      meta: { bodyClass: 'supplier-registration' },
      component: SupplierRegistration
    }
]

const router = new VueRouter({
    routes
});

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => { 
  vueBodyClass.guard(to, next)
  window.scrollTo(0, 0) 

  if( document.location.hash == "#/supplier-registration" ){
    document.getElementsByClassName('site-header__nav-primary')[0].style.display='none'
  }else{
    document.getElementsByClassName('site-header__nav-primary')[0].style.display=''
  }
});

export default router;