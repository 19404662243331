<template>
    <div class="contact-provider">
        <div class="page__heading">
            <h1 class="heading-title">Contact provider<span v-if="receivedResult.length > 1">s</span></h1>
        </div>

        <div class="page__content page__content--contact-provider">
            <div class="contact-provider">
                <div class="contact-provider__details">
                    <p>Please fill the form below and the selected providers will be notified of your interest.</p>

                    <ul class="contact-provider__list">
                        <li v-for="(sr, index) in receivedResult" :key="index" class="list__selected-provider">
                            <div class="selected-provider-container"
                                @click.prevent="removeProvider(sr)">
                                <span class="selected-provider-company-name">{{sr.name}}</span>
                                <span class="remove-provider"></span>
                            </div>
                        </li>
                        <li v-if="receivedResult.length == 0" class="list__no-selected-provider">
                            No providers selected.
                        </li>
                    </ul>

                    <div class="error-message" v-if="receivedResult.length == 0">
                        <span>At least one provider needs to selected. </span>
                        <router-link 
                            to="/home"
                            class="is-link is-intext-link"
                            >Go back.
                        </router-link>
                    </div>

                    <div class="form">
                        <form>
                            <label class="is-label">
                                <span>First name</span>

                                <input name="firstname"
                                    v-model="formFields.firstname"
                                    v-validate="'required'"
                                    :class="{'input': true, 'has-error': errors.has('firstname') }"
                                    type="text">

                                <p v-show="errors.has('firstname')" class="error-message">{{ errors.first('firstname') }}</p>
                            </label>

                            <label class="is-label">
                                <span>Surname</span>

                                <input name="surname"
                                    v-model="formFields.surname"
                                    v-validate="'required'"
                                    :class="{'input': true, 'has-error': errors.has('surname') }"
                                    type="text">

                                <p v-show="errors.has('surname')" class="error-message">{{ errors.first('surname') }}</p>
                            </label>

                            <label class="is-label">
                                <span>Company</span>

                                <input name="company"
                                    v-model="formFields.company"
                                    v-validate="'required'"
                                    :class="{'input': true, 'has-error': errors.has('company') }"
                                    type="text">

                                <p v-show="errors.has('company')" class="error-message">{{ errors.first('company') }}</p>
                            </label>

                            <label class="is-label">
                                <span>Email</span>

                                <input name="email"
                                    v-model="formFields.email"
                                    v-validate="'required|email'"
                                    :class="{'input': true, 'has-error': errors.has('email') }"
                                    type="text">

                                <p v-show="errors.has('email')" class="error-message">{{ errors.first('email') }}</p>
                            </label>

                            <label class="is-label">
                                <span>Phone number</span>

                                <input name="phone"
                                    v-model="formFields.phone"
                                    v-validate="'required|min:10|numeric'"
                                    :class="{'input': true, 'has-error': errors.has('phone') }"
                                    type="text">

                                <p v-show="errors.has('phone')" class="error-message">{{ errors.first('phone') }}</p>
                            </label>

                            <label class="is-label">
                                <span>Enquiry</span>

                                <textarea name="enquiry"
                                    rows="5"
                                    :class="{'input': true, 'has-error': errors.has('enquiry') }"
                                    v-validate="'required|wordLength:250'"
                                    v-model="formFields.details"
                                    ></textarea>

                                <p v-show="errors.has('enquiry')" class="error-message">{{ errors.first('enquiry') }}</p>
                            </label>

                            <div class="cta-layout cta-layout--horizontal cta-layout--left">
                                <button @click="backToResults" class="is-button is-button__outline--primary">&#60; Back to results</button>
                                <invisible-recaptcha
                                    :sitekey="siteKey"
                                    :validate="validate" :callback="validatedContact"
                                    class="is-button is-button__basic--primary"
                                    type="submit" id="do-contact-btn"
                                    :disabled="receivedResult.length == 0">
                                    Send interest
                                </invisible-recaptcha>
                            </div>
                        </form>

                        <p><small>By providing information via this form, you indicate your consent to our use of your personal data in relation to this service and to pass this data to the partners that you have selected.</small></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>    
import contactService from '@/services/contactService.js'
import config from '@/mixins/config';
import InvisibleRecaptcha from 'vue-invisible-recaptcha';
export default {
    name: 'Send',
    props: ['selected_results','search_id'],
    components: {
        "invisible-recaptcha": InvisibleRecaptcha,
    },
    mixins: [config],
    computed:{
        SuppliersId: function() {
            return this.receivedResult.map(function(ele){
            return ele.suppliers_id});
        },
    },
    data:function() {
        return {
            title         : 'Results',
            siteKey       : '',
            receivedResult: [],
            formFields    : {
                firstname     : '',
                surname      : '',
                company       : '',
                email         : '',
                phone         : '',
                enquiry       : '',
            },
            formValid     : false
        }
    },
    methods:{
        backToResults:function() {
            var hasSearchedOnce = true;
            localStorage.setItem("selected_results",JSON.stringify(this.receivedResult));
            this.$router.push({name:'Home',
                params: { hasSearchedOnce }
            })
        },
        validate: function() {
            // recatcha component doesn't like async validate functions, so v-validate checks as you go and returns to a global var, which the recaptcha component can understand
            
            if(this.formValid) {
                return true;
            }

            this.$fire({
                type: 'error',
                title: 'Oops...',
                text: 'Check errors and try again.',
                confirmButtonColor: '#f44336'
            });

            this.$validator.validate();

            return false;
        },
        validatedContact: async function(tok) { 
            const ServiceFeedback = await contactService.contact(tok,this.SuppliersId,this.formFields.firstname,this.formFields.surname,this.formFields.company,this.formFields.email,this.formFields.phone,this.formFields.details,this.search_id);

            if( typeof ServiceFeedback.ok != 'undefined' && ServiceFeedback.ok ){
                
                // clear local storage
                localStorage.setItem("filters_selected" , '');
                localStorage.setItem("filters_pages"    , '');
                localStorage.setItem("selected_results" , '');
                localStorage.setItem("all_results"      , '');
                localStorage.setItem("all_results_count", '');
                localStorage.setItem("current_search_id", '');

                this.$fire({
                    type: 'success',
                    text: 'Sent.',
                    confirmButtonColor: '#4CAF50'
                });
                this.$router.replace('/home')
                return;
            }

            this.$fire({
                type: 'error',
                title: 'Oops...',
                text: 'Failed to send.',
                confirmButtonColor: '#f44336'
            });
        },
        removeProvider:function(item){
            this.$fire({
                    title: "Remove " + item.name + "?",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#f44336',
                    cancelButtonColor: '#808080',
                    confirmButtonText: 'Yes'
                }).then(async (result) => {
                    if (result.value) {
                        var filteredRes = this.receivedResult.filter(function(ele) { return ele !== item })
                        this.receivedResult = filteredRes;
                    }
                    else{
                        this.$fire({
                            position: 'center',
                            text: 'Cancelled.',
                            confirmButtonColor: '#f44336'
                        });
                    }
                });
        }
    },
    mounted :function() {
        if( typeof this.selected_results == 'undefined' || this.selected_results.length==0){
                this.$router.push({name:'Home'})
        }

        this.receivedResult = this.selected_results;

        // wordLength
        this.$validator.extend('wordLength', {
            getMessage: (value, limit) => 'Field limited to '+limit+' words',
            validate: function(value, limit){
                if( (value.match(/\S+/g)) != null ) {
                    return value.match(/\S+/g).length < limit[0];
                }
            }
        });
        const optionsWordLength = {
            name:'wordLength',
            rules: 'wordLength'
        };    
        this.$validator.attach(optionsWordLength);
        this.$validator.validate('wordLength', '');
    },
    created :function() {
        this.siteKey = this.configSitekey();
    },
    watch: {
        "formFields": { // watches for changes to all form fields, and then runs validation on them
            handler: function(){
                var self = this;
                this.$validator.validate().then(result => {
                    self.formValid = result;
                });           
            },
            deep: true
        },
    }
}
</script>
