"use strict";

import axios from 'axios';
import config from '@/mixins/config.js';

const result = {ok:true};

export default {
	visit  : function( suppliers_id,search_id,type ){
		if( config.methods.configIsLocal() ){
			return Promise.resolve( result );
		}

		return axios.post('/api/visit',{
			suppliers_id :suppliers_id,
			search_id    :search_id,
			type         :type
		}).then(function(result){
			return result.data
		})
	},
	contact: function(cap,suppliers_ids,firstname,surname,company,email,phone,enquiry,search_id,type){
		if( config.methods.configIsLocal() ){
			return Promise.resolve( result );
		}

		if(!type) {
			type = '';
		}

		return axios.post(
			'/api/validate',{
			google_captcha:cap,
		}).then(function(result){
			return axios.post('/api/contact',{
				suppliers_ids:suppliers_ids,
				name         :firstname+' '+surname,
				company      :company,
				email        :email,
				phone        :phone,
				details      :enquiry,
				search_id    :search_id,
				jwt          :result.data.jwt,
				type         :type
			});
		}).then(function(result){
			return result.data
		})
	}
}
