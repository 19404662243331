"use strict";

import axios from 'axios';
import config from '@/mixins/config.js';

const result = {ok:true};

export default {
	register: function(cap,company_name_by_trade,company_account_name,company_website,
		email_leads_to,company_description,company_solution_offers,
		company_logo_key,
		employees,regions,
		message
		){
		if( config.methods.configIsLocal() ){
			return Promise.resolve( result );
		}

		return axios.post(
			'/api/validate',{
			google_captcha:cap,
		}).then(function(result){
			//TODO pass JWT if got one or error
			return axios.post('/api/register',{
				
				companyNameByTrade:company_name_by_trade,
				companyAccountName:company_account_name,
				companyWebsite:company_website,
				
				companyLogoKey:company_logo_key,
				
				emailLeadsTo:email_leads_to,
				companyDescription:company_description,
				companySolutionOffers:company_solution_offers,
				
				employees:employees,
				regions:regions,
				message:message,

				jwt:result.data.jwt
			});
		}).then(function(result){
			return result.data
		})
	}
}
