import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VeeValidate from 'vee-validate';
import VueSimpleAlert from "vue-simple-alert";
import VueGtag from "vue-gtag";

Vue.use(VeeValidate);
Vue.use(VueSimpleAlert);

if( document.location.host == 'providers.partnerplus.uk' ){
//https://matteo-gabriele.gitbook.io/vue-gtag/auto-tracking
//vue-gtag automatically tracks the state name and state change with "pageTrackerTemplate"
Vue.use(VueGtag, {
  pageTrackerTemplate(to) {
    return {
      page_title: to.name,
      page_path: to.path
    }
  },
  config: { 
    id: "G-DDQX4D5M6K",
  },
}, router);
}

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
