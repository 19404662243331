<template>
    <router-view></router-view>
</template>

<script>    
export default {
    methods:{
        redirectToDefaultState:function(){
            if( this.$route.path == '/' ){
                this.$router.replace('/home');
            }
        }
    },
    mounted :function() {
        this.redirectToDefaultState();
    },
    created :function() {
        this.redirectToDefaultState();
    },
    updated :function() {
        this.redirectToDefaultState();
    }
}
</script>
