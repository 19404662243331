<template>
	<div>
		<router-view />
	</div>
</template>

<script>
	import "@/assets/css/main-bt.css";

	export default {
		name: 'App',
	}
</script>
