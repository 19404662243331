<template>
	<div class="find-a-supplier"
		:class="{ 'find-a-supplier--stage-1' : !hasSearchedOnce, 'find-a-supplier--stage-2' : hasSearchedOnce }"
		ref="ScrollSupplier"
		>
		<section v-if="!hasSearchedOnce" class="banner-container" role="img" aria-label="Tech Provider Search. The direct route to IT help."></section>

		<div class="page__heading">
			<h1 class="is-reseller heading-title">Find a provider <sup class="version">{{site_version}}</sup></h1>

			<h1 class="is-r2r heading-title">Find a BT Wholesale Partner <sup class="version">{{site_version}}</sup></h1>
		</div>

		<div class="page__content page__content--finding-a-supplier">
			<div class="finding-a-supplier">
				<p v-if="hasSearchedOnce" class="finding-a-supplier__introduction">See which provider is right for you.</p>

				<search-filters
					v-if="hasSearchedOnce"
					v-model="filters.selected"
					:options="filters.options"
					:pages="filters.pages"
					@filters-updated="updateFilters"
					@pages-updated="updatePageSize"
					:results="resultsNumbers"
				></search-filters>

				<form class="finding-a-supplier__filter-by"
					v-else
				>
					<div v-if="attributes.length > 0">
						<h3 v-if="!hasSearchedOnce">Filter below to see which <span class="is-reseller">provider</span><span class="is-r2r">reseller</span> is right for you</h3>

						<p v-if="!hasSearchedOnce" class="is-r2r company-type-description">Selected partners resell our network to smaller resellers who do not meet BT Wholesale&rsquo;s minimum criteria. A great way to start or build your reseller business.</p>

						<div v-if="hasSearchedOnce">
							<input type="text" 
							name="search" 
							v-model="filters.selected.search"
							placeholder="Search">
						</div>
						
						<p class="is-label is-label--filter-by is-label--filter-by-type">Choose a solution</p>
						<div class="filter-by--type">
							<label
								v-for="(d, i) in attributes" 
								:key="i" 
								:class="{ 'type-icon' : !hasSearchedOnce, 'selector__label' : hasSearchedOnce, 'is-checked' : filters.selected.attributes.indexOf(d.attributes_id) > -1 }"
								>
								<input type="checkbox"
									v-model="filters.selected.attributes"
									v-validate="'required'"
									:id="d.id"
									:value="d.attributes_id"
									name="attributes">
								<span class="type-text">{{d.name}}</span>

								<div v-if="hasSearchedOnce" class="selector__indicator"></div>
							</label>
						</div>

						<p class="is-label is-label--filter-by is-label--filter-by-regions-and-countries">Regions/countries required</p>

						<div class="filter-by--country">
							<label class="selector__label is-checked">
								{{regions[0].text}}
								<input 
									type="checkbox"
									:value="regions[0].id"
									v-model="filters.selected.regions_list"
									@change="checkRegions(regions[0].id)"
									ref="region__all"
								>
								<div class="selector__indicator">
								</div>
							</label>

							<label class="selector__label is-checked"
								v-for="country in regions[0].children"
								:key="country.id"
							>
								{{country.text}}
								<input 
									type="checkbox"
									:value="country.id"
									v-model="filters.selected.regions_list"
									@change="checkRegions(country.id)"
									:ref="'region__'+country.id"
								>
								<div class="selector__indicator">
								</div>
							</label>
						</div>

						<div v-for="country in regions[0].children"
							:key="country.id"
							v-show="typeof country.children != 'undefined'"
							:class="'filter-by--region filter-by--region--'+country.id">
							<p><strong>{{country.text}} regions</strong></p>
							<label class="selector__label is-checked"
								v-for="region in country.children"
								:key="region.id"
							>
								{{region.text}}
								<input 
									type="checkbox"
									:value="region.id"
									v-model="filters.selected.regions_list"
									@change="checkRegions(region.id)"
									:ref="'region__'+region.id"
								>
								<div class="selector__indicator">
								</div>
							</label>
						</div>

						<p class="error-message" v-if="error">{{error}}</p>

						<div class="form-group">
							<invisible-recaptcha
								:sitekey="siteKey"
								:validate="validate" :callback="validatedSearch"
								class="is-button is-button__basic--primary is-button--find-a-provider"
								type="submit" id="do-search-btn" :disabled="loading">
								Find a provider
							</invisible-recaptcha>
						</div>
					</div>
				</form>

				<form class="finding-a-supplier__results" v-if="hasSearchedOnce" @submit.prevent="SendResults">
					<div class="results">
						<table class="results--list-of-suppliers responsive-table"
							v-if="results.length > 0"
						>
							<thead>
								<tr>
									<th scope="col">Provider</th>
									<th scope="col">Description</th>
									<th scope="col">Solutions</th>
									<th scope="col">Regions/countries</th>
									<th scope="col">Contact</th>
									<th scope="col">Selected</th>
								</tr>
							</thead>

							<tbody>
								<tr v-for="(r, i) in sortedResults" :key="i"
									:class="{ 'supplier-selected' : r.checked == true,'supplier-promoted':r.is_promoted }"
									>
									<td data-label="Name" class="supplier--name" valign="top" width="15%">
										<img :src="r.image_url" :alt="r.name" class="provider--logo"><br/>
										<small class="provider--tier">{{getTierInfoForValue(r.tier).label}}</small>

										<visit-website
											:provider="r"
											:btn-idx="i"
											@visit-website="visitExternalSite"
										></visit-website>
									</td>

									<td data-label="Description" class="supplier--description" valign="top">
										<strong>{{r.name}}</strong> &ndash; <span v-html="getShortDescription(r.description)"></span><br>
										<a class="is-link is-intext-link" @click="openModal(r)">Find out more</a>
									</td>

									<td data-label="Solution" class="supplier--type" valign="top">
										<span v-for="(attr,j) in r.attributes" :key="j">
											{{attributeLabel(attr)}}<span v-if="j!=r.attributes.length-1">, </span>
										</span>
									</td>

									<td data-label="Regions" class="supplier--regions" valign="top">
										{{regionsLabels(r.regions_list)}}
									</td>

									<td data-label="Contact" class="supplier--contact" valign="top">
										<a class="is-button is-button__basic--primary"
											@click="contactProvider(r)"
										>Contact</a>
									</td>

									<td data-label="Selected" class="supplier--selected">
										<label class="selector__label">
											<input type="checkbox"
												v-validate="'required'"
												:value="r"
												name="results"
												:checked="isSelected(r)"
												@click="toggleSelected(r)"
											>
											<div class="selector__indicator"></div>
										</label>
									</td>
								</tr>
							</tbody>
						</table>

						<div class="results--empty" v-if="results.length == 0 && hasSearchedOnce">
							<p>No results found. Try adjusting your search and filter settings.</p>
						</div>

						<div class="paginationContainer"
							v-if="filters.pages.total > 1"
						>
							<vuejs-paginate
								v-model="filters.pages.current"
								:page-count="filters.pages.total"
								:prev-text="''"
								:next-text="''"
								:click-handler="newPage"
								:first-last-button="false"
								:container-class="'pagination'"
								:page-class="'pagination__page-number'"
								:page-link-class="'page-number '"
								:prev-class="'pagination__prev-icon'"
								:prev-link-class="'is-link is-icon-link is-link--previous'"
								:next-class="'pagination__next-icon'"
								:next-link-class="'is-link is-icon-link is-link--next'"
							>
							</vuejs-paginate>
						</div>

						<div ref="error"
							v-if="results.length > 0"
							class="error-message-container--select-provider-error"
							><p v-if="resultsError" class="error-message">{{resultsError}}</p></div>

						<div v-if="loading" class="loading-placeholder">
							<!-- Loading results -->
							<div class="spinner-box">
								<div class="spinner"></div>
							</div>
						</div>
						
						<div class="finding-a-supplier__list-of-suppliers-selected" 
							v-if="hasSearchedOnce"
							>
							<div class="finding-a-supplier__selected-list-container">
								<div class="finding-a-supplier__selected-list"
									v-if="selected_list_active && selected_results.length > 0"
									>
									<h4>Selected providers <a class="remove__indicator" @click="toggleList"></a></h4>

									<table class="table--selected-list">
										<tbody>
											<tr v-for="(sr, $index) in selected_results" :key="$index"
											>
												<td width="25%">{{sr.name}}</td>
												<td><span v-for="(attr,j) in sr.attributes" :key="j">{{attributeLabel(attr)}}<span v-if="j!=sr.attributes.length-1">, </span></span></td>
												<td width="40"><a class="bin__indicator" @click="removeSelected($index)"></a></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

							<div class="list-of-suppliers-selected">
								<div class="cta-layout cta-layout--horizontal cta-layout--space-between cta-layout--selected-suppliers">
									<p>
										<strong>{{selected_results.length}} provider<span v-if="selected_results.length != 1">s</span> selected</strong>
											<span v-if="selected_results.length > 0">&nbsp;(<a v-if="selected_list_active" @click="toggleList" class="is-link is-intext-link">Hide selected</a><a v-else @click="toggleList" class="is-link is-intext-link">Show selected</a>)</span>
									</p>

									<input type="submit" value="Contact providers(s)" :disabled="results.length == 0">
								</div>
							</div>
						</div>
					</div>
				</form>
				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
	import InvisibleRecaptcha from 'vue-invisible-recaptcha';
	import attribute_list from '@/services/attributeService.js';
	import resultsService from '@/services/resultsService.js';
	import contactService from '@/services/contactService.js'
	import VueJsPaginate from 'vuejs-paginate';
	import config from '@/mixins/config';

	export default {
		name: 'Home',
		components: {
			"invisible-recaptcha": InvisibleRecaptcha,
			searchFilters: () => import("@/components/searchFilters.vue"),
			visitWebsite: () => import("@/components/visitWebsite.vue"),
			"vuejs-paginate": VueJsPaginate,
		},
		mixins: [config],
		computed:{
			sortedResults() {
				var self = this;
				//slice first to make sure we return a copy - https://stackoverflow.com/questions/49869081/unexpected-side-effect-in-computed-property
				return this.results.slice().sort(function (a, b) {
					if (a.is_promoted !== b.is_promoted) {
						if (a.is_promoted) return -1;
						return 1;
					}
					// same promote level, sort by name
					return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
				}).filter(function(el, idx) {
					return idx >= self.startIdx && idx < self.endIdx;
				});
			},
			startIdx() {
				return (this.filters.pages.current-1)*this.filters.pages.size;
			},
			endIdx() {
				return this.filters.pages.current*this.filters.pages.size >= this.results_count?this.results_count:this.filters.pages.current*this.filters.pages.size;
			},
			resultsNumbers() {
				return {
					start: this.startIdx,
					end  : this.endIdx,
					total: this.results_count
				}
			},
		},
		data:function() {
			return {
				title: 'Find a provider',
				site_version   : '',
				attributes     : [],
				error          : null,
				loading        : true,
				hasSearchedOnce: false,
				results        : [],
				results_count  : 0,
				resultsError   : false,
				siteKey        : '',
				search         : null,
				selected_results: [],
				selected_list_active: false,
				filters        : {
					pages      : {
						current : 1,
						size    : 10,
						total   : 1
					},
					options: [
						{
							id         : 'search',
							label      : 'Search provider',
							type       : 'text',
							placeholder: 'Search provider',
							sort_order : 1,
							flags      : '1100'   // flags: vertical|horizontal|required|hasOptions
						},
						{
							id         : 'attributes',
							label      : 'Choose a solution',
							type       : 'checkbox-multi',
							options    : [],
							sort_order : 2,
							flags      : '1001'   // flags: vertical|horizontal|required|hasOptions
						},
						{
							id         : 'employees',
							label      : 'Accommodates number of employees up to',
							type       : 'select-single',
							options    : [],
							sort_order : 3,
							flags      : '1001'   // flags: vertical|horizontal|required|hasOptions
						},
						{
							id         : 'regions_list',
							label      : 'Regions/countries provider deliver service to',
							type       : 'tree-component',
							options    : [],
							sort_order : 4,
							flags      : '1001'   // flags: vertical|horizontal|required|hasOptions
						},
						{
							id         : 'tier',
							label      : 'Provider tier',
							type       : 'checkbox-multi',
							options    : [],
							sort_order : 5,
							flags      : '1001'   // flags: vertical|horizontal|required|hasOptions
						},

					],
					selected: {
						'search'      : '',
						'attributes'  : [],
						'employees'   : '',
						'regions_list': [],
						'tier'        : [],
					}
				},
				regions			 : this.configRegionsTreeData(),
				current_search_id: '',
				visit_provider   : {}
			}
		},
		methods: {
			toggleSelected: function(item) {
				var idx = this.getSelectedIdx(item);
				if( idx != -1) {
					this.selected_results.splice(idx,1);
				} else {
					this.selected_results.push(item);
				}
			},
			getSelectedIdx: function(item){
				for (var i = 0; i < this.selected_results.length; i++) {
					if(item.suppliers_id == this.selected_results[i].suppliers_id) {
						return i;
					}
				}
				return -1;
			},
			isSelected: function(attr){
				return this.selected_results.filter(function(el) {
					return el.suppliers_id == attr.suppliers_id;
				}).length > 0
			},
			checkRegions: function(id) {
				var self = this;
				var hasBeenAdded           = this.filters.selected.regions_list.indexOf(id) != -1;
				var isAll                  = id == 'all';
				var isRegion               = id.split('-').length == 2;
				var isCountry              = !isAll && !isRegion;
				var all_regions_flat       = this.getAllRegionsFlat();
				var countryCount           = all_regions_flat.filter(function(el){return el.split('-').length == 2 && el.split('-')[0] == id.split('-')[0]}).length;
				var parentIdx              = this.filters.selected.regions_list.indexOf(id.split('-')[0]);
				var selectedCount          = this.filters.selected.regions_list.filter(function(el){return el.split('-').length == 2 && el.split('-')[0] == id.split('-')[0]}).length;


				if(isAll && hasBeenAdded) { // if isAll and hasBeenAdded - check all
					this.filters.selected.regions_list = all_regions_flat;
				} else if (isAll && !hasBeenAdded) { // if isAll and !hasBeenAdded - uncheck all
					this.filters.selected.regions_list = [];
				} else if (isCountry && hasBeenAdded) { // if isCountry and hasBeenAdded - check all child regions
					all_regions_flat.filter(function(el) {
						if(
							el.split('-').length == 2 && 
							el.split('-')[0] == id && 
							self.filters.selected.regions_list.indexOf(el) == -1
						){
							self.filters.selected.regions_list.push(el)
						}
					});
				} else if (isCountry && !hasBeenAdded) { // if isCountry and !hasBeenAdded - uncheck child regions
					all_regions_flat.filter(function(el) {
						if(el.split('-').length == 2 && el.split('-')[0] == id){
							self.filters.selected.regions_list.splice(self.filters.selected.regions_list.indexOf(el),1);
						}
					});
				} else if (isRegion && hasBeenAdded) { // if isregion and hasBeenAdded - check if all regions are now added, if so check parent
					if(
						countryCount == selectedCount &&
						this.filters.selected.regions_list.indexOf(id.split('-')[0]) == -1
						) {
						this.filters.selected.regions_list.push(id.split('-')[0]);
					}
				} else if (isRegion && !hasBeenAdded) { // if isregion and !hasBeenAdded - uncheck parent
					if(parentIdx > -1) {
						this.filters.selected.regions_list.splice(parentIdx,1);
					}
				}

				if(hasBeenAdded){
					var allCountriesCount      = all_regions_flat.filter(function(el){return el.split('-').length == 1 && el != 'all'}).length;
					var selectedCountriesCount = this.filters.selected.regions_list.filter(function(el){return el.split('-').length == 1 && el != 'all'}).length;
					if(
						selectedCountriesCount == allCountriesCount && 
						this.filters.selected.regions_list.indexOf('all') == -1
					){
						this.filters.selected.regions_list.push('all');
					}
				} else {
					var allIdx = this.filters.selected.regions_list.indexOf('all');

					if(allIdx > -1) { // remove 'all' value, if selected
						this.filters.selected.regions_list.splice(allIdx,1);
					}
				}
			},
			updatePageSize: function(ret) {
				this.filters.pages.current = 1;
				this.filters.pages.size    = ret.pagesize;
				this.filters.pages.total   = Math.ceil(this.results_count/this.filters.pages.size);
			},
			newPage: function(pageNum) {
				this.filters.pages.current = Number(pageNum);
				this.$refs['ScrollSupplier'].scrollIntoView({behavior: "smooth"})
			},
			updateFilters: function(ret) {
				this.validatedSearch(ret.tok);
			},
			toggleList:function(){
				this.selected_list_active = !this.selected_list_active;
			},
			removeSelected:function(idx){
				this.selected_results.splice(idx,1);

				if(this.selected_results.length == 0){
					this.selected_list_active = false;
				}
			},
			attributeLabel:function(attr){
				var f = this.attributes.filter(function(e){ return e.attributes_id == attr });
				if(f.length == 1){
					return f[0].name;
				}
				return "";
			},
			regionsLabels:function(regions_list){
				if(regions_list == null) {
					return '';
				}

				var regions_split = regions_list.split(',');
				var ret = [];

				var grouped_regions = this.getGroupedRegions(regions_split);

				for (var i = 0; i < grouped_regions.length; i++) {
					var region = this.getRegionById(grouped_regions[i]);
					ret.push( region.text );
				}

				return ret.join(', ');

			},
			validatedSearch: async function(tok){
				this.$refs['ScrollSupplier'].scrollIntoView({behavior: "smooth"})
				this.loading = true;

				this.filters.pages.current = 1;

				var res = await resultsService.search(tok,this.filters.selected);
				this.results             = res.results;
				this.results_count       = res.results.length;
				this.current_search_id   = res.search_id;
				this.filters.pages.total = Math.ceil(this.results_count/this.filters.pages.size);

				this.hasSearchedOnce = true;
				this.loading = false;
			},
			validate: function() {	
				if ( 
					this.filters.selected.attributes.length == 0 && 
					this.filters.selected.regions_list.length == 0 && 
					this.filters.selected.search == ''
				) {
					this.error = "At least one option is required.";
					return false;
				} 
				
				this.error = null;
				return true;					
			},
			SendResults: function(){
				if ( this.selected_results.length <= 10 && this.selected_results.length > 0) {
					this.error = null;
					localStorage.setItem("filters_selected", JSON.stringify(this.filters.selected));
					localStorage.setItem("filters_pages", JSON.stringify(this.filters.pages));
					localStorage.setItem("selected_results",JSON.stringify(this.selected_results));
					localStorage.setItem("all_results",JSON.stringify(this.results));
					localStorage.setItem("all_results_count",JSON.stringify(this.results_count));
					localStorage.setItem("current_search_id",JSON.stringify(this.current_search_id));
					this.$router.push({name:'Send', params: {selected_results: this.selected_results, search_id: this.current_search_id}});

					return true
				}

				else if (this.selected_results.length > 10) {
					this.resultsError = "Can't select more than 10 providers."
					this.$refs['error'].scrollIntoView({behavior: "smooth"})
					return false
				}

				this.resultsError = "Select at least one provider before you can send.";
				this.$refs['error'].scrollIntoView({behavior: "smooth"})
				
				return false;
			},
			openModal: function(record) {
				var tier = this.getTierInfoForValue(record.tier);

				var attrs = [];
				for (var i = 0; i < record.attributes.length; i++) {
					attrs.push( this.attributeLabel(record.attributes[i]) );
				}

				var _desc = record.description.split(/\r?\n/);
				var desc_formatted = '';

				for (var j = 0; j < _desc.length; j++) {
					desc_formatted += "<p>"+_desc[j]+"</p>";
				}

				var html = desc_formatted+//'<p>'+record.description+'</p>'+
				'<p><strong>Solutions '+record.name+' provide</strong><br/>'+
				attrs.join(', ')+'</p>'+
				'<p><strong>Suited for companies with employees up to</strong><br/>'+
				record.employees+'</p>'+
				'<p><strong>Region/countries '+record.name+' deliver service to</strong></br>'+
				this.regionsLabels(record.regions_list)+'</p>'+
				'<p><strong>Partner tier</strong>'+
				'<img class="provider-details__logo" src="'+tier.image+'" alt="' +tier.label+ '" />';

				this.$fire({
					title: record.name+' ('+tier.label+')', // can use html here eg: '<h2 class="class-name">'+record.name+' <span>('+tier.label+')</span>'+'</h2>'
					html: html,
					showCloseButton: true,
					showConfirmButton: false,
					customClass: { //https://sweetalert2.github.io/#customClass
						container: 'modal--provider-details',
						header: 'provider-details__header',
						title: 'provider-details__title',
						image: 'provider-details__logo'
					},
					width: '100%',
				});
			},
			getShortDescription: function(description) {
				return description.split(' ').slice(0, 20).join(' ')+'... ';
			},
			contactProvider: function(record) {
				var idx = this.getSelectedIdx(record);
				if( idx == -1) {
					this.selected_results.push(record);
				}
				this.SendResults();
			},
			visitExternalSite: async function(ret){
				await contactService.visit( ret.provider.suppliers_id,this.current_search_id,'external' );
				window.open(ret.provider.website_url);
			}
		},
		mounted:async function(){
			var _attrs      = await attribute_list.getAll();
			this.attributes =_attrs.rows;
			this.loading    = false;
			this.siteKey    = this.configSitekey();
			if (this.$route.params.hasSearchedOnce) {
				this.hasSearchedOnce  = true;
				this.filters.selected = JSON.parse(localStorage.getItem("filters_selected")) !== null ? JSON.parse(localStorage.getItem("filters_selected")):this.filters.selected;
				this.filters.pages    = JSON.parse(localStorage.getItem("filters_pages")) !== null ? JSON.parse(localStorage.getItem("filters_pages")):this.filters.pages;
				this.selected_results = JSON.parse(localStorage.getItem("selected_results")) !== null ? JSON.parse(localStorage.getItem("selected_results")):[];
				this.results          = JSON.parse(localStorage.getItem("all_results")) !== null ? JSON.parse(localStorage.getItem("all_results")):[];
				this.results_count    = JSON.parse(localStorage.getItem("all_results_count")) !== null ? JSON.parse(localStorage.getItem("all_results_count")):0;
				this.current_search_id    = JSON.parse(localStorage.getItem("current_search_id")) !== null ? JSON.parse(localStorage.getItem("current_search_id")):'';
				this.newPage(this.filters.pages.current);
			}

			var currentURL = window.location.hostname;
			if( currentURL.indexOf("testsite") == -1) {
				this.site_version = 'beta';
			} else {
				this.site_version = 'testing site';
			}
		}
	}
</script>