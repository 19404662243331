"use strict";

import axios from 'axios';
import config from '@/mixins/config.js';

import {supplierData} from '/dummy-data/suppliers.js';

function hasAttribute(search,list) {
	var srch = search.split(',');
	
	for (var i = 0; i < srch.length; i++) {
	
		if( list.indexOf(srch[i]) > -1 ){
			return true;
		}
	}

	return false;
}

export default {
	search: function(cap,filters){
		if( config.methods.configIsLocal() ){
			var allCount = supplierData.filter(result => {
				var hasFilters = [];
				var matchedFilters = [];
				if(
					typeof filters.search != 'undefined' && 
					filters.search != ''
				) {
					hasFilters.push('search');
					if(result.name.toLowerCase().indexOf(filters.search.toLowerCase()) > -1) {
						matchedFilters.push('search');
					}
				}
				if(
					typeof filters.attributes != 'undefined' &&
					filters.attributes.length > 0
				) {
					hasFilters.push('attributes');

					let attrstext = filters.attributes.toString();

					if(typeof result.attributes[0].attributes_id != 'undefined') {
						result.attributes = result.attributes.map( attr => attr.attributes_id ).sort();
					}
					if(hasAttribute(attrstext,result.attributes) ){
						matchedFilters.push('attributes');
					}
				}
				if(typeof filters.employees != 'undefined' &&
					filters.employees !== ''
				) {
					hasFilters.push('employees');
					if(result.employees == filters.employees){
						matchedFilters.push('employees');
					}
				}
				if(typeof filters.regions_list != 'undefined' && 
					filters.regions_list.length > 0) {
					hasFilters.push('regions_list');
					
					if( hasAttribute(filters.regions_list.join(','),result.regions_list) ) {
						matchedFilters.push('regions_list');
					}
				}
				if(
					typeof filters.tier != 'undefined' &&
					filters.tier.length > 0
				) {
					hasFilters.push('tier');
					let tiertext = filters.tier.toString();
					if( hasAttribute(tiertext,result.tier) ){
						matchedFilters.push('tier');
					}
				}
				for (var i = 0; i < hasFilters.length; i++) {
					if( matchedFilters.indexOf(hasFilters[i]) == -1) {
						return false;
					}
				}
				return true;
			});

			return {
				results: allCount,
				search_id: (Math.random() + 1).toString(36).substring(2)
			};
		}

		return axios.post(
			'/api/validate',{
			google_captcha:cap
		}).then(function(result){ 
			//TODO pass JWT if got one or error
			return axios.post('/api/search',{
				jwt:result.data.jwt,
				attributes  : filters.attributes,
				search      : filters.search,
				regions_list: filters.regions_list,
				employees   : filters.employees,
				tier        : filters.tier
			});
		}).then(function(result){ 
			return result.data
		})
	}
}