<template>
    <div class="register-as-a-supplier">
        <div class="page__heading">
            <h1 class="heading-title">{{pageTitle}}</h1>
        </div>

        <div class="page__content page__content--register-as-a-supplier">
            <div class="content content--register-as-a-supplier">
                <div class="register-as-a-supplier__information">
                    <h3>Help us find customers for you</h3>
                    <p>Please provide us with the details below which will help us provide you with potential leads. These are going to be fed into a trial website, where businesses looking for help with their IT requirements can find partners of BT Wholesale.</p>

                    <form class="form--register-as-a-supplier">
                        <div class="form__section">
                            <h4>1. Your company</h4>
                            <label class="is-label">
                                Company name you trade by (to be displayed)

                                <input name="companyNameByTrade"
                                    v-model="formFields.companyNameByTrade"
                                    v-validate="'required'"
                                    :class="{'input': true, 'has-error': errors.has('companyNameByTrade') }"
                                    type="text">

                                <p v-show="errors.has('companyNameByTrade')" class="error-message">{{ errors.first('companyNameByTrade') }}</p>
                            </label>

                            <label class="is-label">
                                Account company name (if different &ndash; not displayed)

                                <input name="companyAccountName"
                                    v-model="formFields.companyAccountName"
                                    type="text"
                                >
                            </label>

                            <label class="is-label">
                                Your company website URL

                                <input name="companyWebsite"
                                    placeholder="https://www.example.com"
                                    v-model="formFields.companyWebsite"
                                    v-validate="{'url': {require_protocol: true },'required': true}"
                                    :class="{'input': true, 'has-error': errors.has('companyWebsite') }"
                                    type="text">

                                <p v-show="errors.has('companyWebsite')" class="error-message">{{ errors.first('companyWebsite') }}</p>
                            </label>

                            <label class="is-label">
                                <span>Please provide your logo in either JPG, PNG or GIF format, on a white background.</span>

                                <vue-plupload
                                    id="supplierRegister"
                                    class=""
                                    v-show="uploadPercent == 0 && !loading"
                                    label="Upload image"
                                    @progress="handleProgress" @uploaded="handleUploaded" 
                                    @added="handleAdded" @error="handleError" 
                                    @cancel="handleCancel" 
                                ></vue-plupload>

                                <em v-if="uploadStageFile">
                                    <br>(uploaded image: {{uploadStageFile}})<br>
                                </em>

                                <span v-show="errors.has('companyLogo')" class="error-message">{{ errors.first('companyLogo') }}</span>
                                <span v-show="uploadError != ''" class="error-message">{{ uploadError }}</span>
                            </label>
                        </div>

                        <div class="form__section">
                            <h4>2. Getting in touch</h4>

                            <label class="is-label">
                                Where should we send potential leads? Please provide a monitored mailbox so that leads can be responded to quickly.

                                <input name="emailLeadsTo"
                                    v-model="formFields.emailLeadsTo"
                                    v-validate="'required|email'"
                                    :class="{'input': true, 'has-error': errors.has('companyWebsite') }"
                                    type="text">

                                <p v-show="errors.has('emailLeadsTo')" class="error-message">{{ errors.first('emailLeadsTo') }}</p>
                            </label>
                        </div>

                        <div class="form__section">
                            <h4>3. About your company</h4>

                            <label class="is-label">
                                Up to 250 words that tells potential buyers why you are so amazing and they should choose you.

                                <textarea name="companyDescription"
                                    v-model="formFields.companyDescription"
                                    v-validate="'required|wordLength:250'"
                                    :class="{'input': true, 'has-error': errors.has('companyWebsite') }"
                                    rows="5" cols="50"
                                    type="text"></textarea>
                            </label>

                            <p v-show="errors.has('companyDescription')" class="error-message">{{ errors.first('companyDescription') }}</p>
                        </div>

                        <div class="form__section">
                            <h4>4. What do you sell?</h4>

                            <p class="is-label">Which solutions do you offer to your customers? (Select all that apply)</p>

                             <label v-for="(solutionOffer, i) in solutionOffers"
                                    v-bind:key="i"
                                    :for="solutionOffer.name.replace(/\s/g, '').toLowerCase()"
                                    class="selector__label">
                                <input
                                    :id="solutionOffer.name.replace(/\s/g, '').toLowerCase()"
                                    :value="solutionOffer"
                                    v-model="formFields.companySolutionOffers"
                                    v-validate="'required'"
                                    name="solution-offer"
                                    type="checkbox" />
                                {{ solutionOffer.name }}

                                <div class="selector__indicator"></div>
                            </label>

                            <p v-show="errors.has('solution-offer')" class="error-message">{{ errors.first('solution-offer')?'At least one option is required':'' }}</p>
                        </div>

                        <div class="form__section">
                            <h4>5. Who are your customers? </h4>

                            <label for="number-of-employees" class="is-label">Number of employees (up to)</label>

                            <!-- this is duplicated in suppliersEdit -->
                            <select id="number-of-employees"
                                name="employees"
                                v-model="formFields.employees"
                                v-validate="'required'"
                            >
                                <option></option>
                                <option v-for="e in employeeOptions" :key="e.value" :value="e.value">{{e.label}}</option>
                            </select>

                            <p v-show="errors.has('employees')" class="error-message">{{ errors.first('employees')?'This is required':'' }}</p>
                        </div>

                        <div class="form__section">
                            <h4>6. Where do you work?</h4>

                            <p class="is-label">Regions/countries you deliver services to:</p>

                             <LiquorTree ref="tree2"
                                  :data="regionTreeData"
                                  :options="treeOptions"
                                  v-on:node:checked="onNodeCheck"
                                  class="LiquorTree LiquorTree--deliver-service-to"
                                  aria-busy="true"
                            />                            
                            <p v-show="errors.has('companyRegions')" class="error-message">{{ errors.first('companyRegions') }}</p>
                        </div>

                        <div class="form__section">
                            <h4>7. Anything else?</h4>

                            <label for="feedback-message" class="is-label">Is there anything you think that we&rsquo;ve missed that could help us improve how we filter and provide details to your potential customers?</label>

                            <textarea id="feedback-message" name="message" rows="5"
                                v-model="formFields.message" 
                                v-validate="'wordLength:250'"
                            ></textarea>
                            <p v-show="errors.has('message')" class="error-message">{{ errors.first('message') }}</p>
                        </div>

                        <div class="cta-layout cta-layout--horizontal cta-layout--left">
                            <invisible-recaptcha
                                :sitekey="siteKey"
                                :validate="validate" :callback="validatedSupplierRegistration"
                                class="is-button is-button__basic--primary"
                                type="submit" id="submit-button--register-as-supplier">
                                Register as a provider
                            </invisible-recaptcha>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LiquorTree from 'liquor-tree';
    import InvisibleRecaptcha from 'vue-invisible-recaptcha';
    import config from '@/mixins/config';

    import VuePlupload from '@/components/vue-plupload.vue';

    import registerService from '@/services/registerService.js';
    import attribute_list from '@/services/attributeService.js';

    import validateDictionary from '../mixins/validateDictionary.js';

    export default {
        name: 'supplier-registration',
        components: {
            "invisible-recaptcha": InvisibleRecaptcha,
            VuePlupload,LiquorTree
        },
        mixins: [config,validateDictionary],
        data:function() {
            return {
                regionTreeData: [],
                treeOptions: {
                    checkbox: true,
                    checkOnSelect:true
                },

                employeeOptions:[],
                siteKey : '',
                pageTitle: 'Register as a provider',
                solutionOffers: []
                ,
                formFields : {
                    companyNameByTrade   : '',
                    companyAccountName   : '',
                    companyWebsite       : '',
                    emailLeadsTo         : '',
                    companyDescription   : '',
                    companySolutionOffers: [],
                    companyLogoKey       : '',
                    employees:'',
                    message:''

                },
                loading           : false,
                uploadStageFile   :'',
                uploadStageResult : {},
                uploadPercent     : 0,
                uploadError       : '',
            }
        },
        methods:{
            validate: function() {
                // recatcha component doesn't like async validate functions, so v-validate checks as you go and returns to a global var, which the recaptcha component can understand

                if(this.formValid) {
                    return true;
                }

                this.$fire({
                    type: 'error',
                    title: 'Oops...',
                    text: 'Check errors and try again.',
                    confirmButtonColor: '#f44336'
                });

                this.$validator.validate();

                return false;
            },
            validatedSupplierRegistration: async function(tok) {
                var selected = this.$refs.tree2.findAll({ state: { checked:true }})
                var selected_ids = selected.map(function(e){ return e.id }).join(',');

                const ServiceFeedback = await registerService.register(tok,this.formFields.companyNameByTrade,this.formFields.companyAccountName,this.formFields.companyWebsite,
                    this.formFields.emailLeadsTo,this.formFields.companyDescription,this.formFields.companySolutionOffers, 
                    this.formFields.companyLogoKey,
                    this.formFields.employees,selected_ids,
                    this.formFields.message
                    );

                if( typeof ServiceFeedback.ok != 'undefined' && ServiceFeedback.ok ){
                    this.$fire({
                        type: 'success',
                        text: 'Thanks for submitting, we’ll be in touch with an update on your submission.',
                        confirmButtonColor: '#4CAF50'
                    });
                    this.$router.replace('/')
                    return;
                }

                this.$fire({
                    type: 'error',
                    title: 'Oops...',
                    text: 'Failed to send.',
                    confirmButtonColor: '#f44336'
                });
            },
            handleAdded(uploader, files) {
                this.loading=true;
                this.uploadError='';
                // eslint-disable-next-line no-console
                files.forEach((file) => console.log(`begin upload file: ${file.name} ${"\n"}`));
                var size_k = files[0].size/1024;
                if( size_k > 15*1024 ){
                    // eslint-disable-next-line no-console
                    console.log("The file size is larger than the recomended maximum of 15 megabytes");
                }
            },
            handleProgress(uploader, file) {
                // eslint-disable-next-line no-console
                console.log(`file: ${file.name} uploading, progress: ${file.percent} ${"\n"}`);
                this.uploadPercent = file.percent;
            },
            handleError(uploader, err) {
                // eslint-disable-next-line no-console
                console.log(`upload error:${"\n"}======${"\n"} ${JSON.stringify(err)} ${"\n"}======${"\n"}`);

                this.uploadError= "File not uploaded, please try again.";
                this.uploadStageFile= '';
                this.uploadStageResult= {};
                this.uploadPercent= 0;
                this.formFields.companyLogoKey = '';
                this.loading=false;
            },
            handleUploaded(uploader, file, result) {
                this.uploadStageResult         = result;
                this.uploadStageFile           = file.name;
                this.formFields.companyLogoKey = result.key;
                this.loading                   = false;
            },
            handleCancel(/*uploader, file*/) {
                if( this.configIsLocal() ) {
                    this.uploadPercent             = 100;
                    this.uploadStageFile           = "test_file_abc";
                    this.formFields.companyLogoKey = "uploads/test_key_123/test_file_abc.jpg";
                }
                
                this.loading = false;
                return;
            },
            onNodeCheck() {
                var self = this;
                this.$validator.validate().then(result => {
                    self.formValid = result;
                });
            }
        },
        created: async function() {
            var _attrs = await attribute_list.getAll();
            this.solutionOffers=_attrs.rows;

            var self = this;

            this.$validator.localize(this.dictionaryGetAll());

            //logo
            this.$validator.extend('companyLogo', {
                getMessage: () => 'This is required',
                validate: function(value){
                    return value.companyLogoKey != '';
                }
            });
            const optionsLogo = {
                name:'companyLogo',
                rules: 'companyLogo',
                getter:function() {
                    return self.formFields;
                }
            };    
            this.$validator.attach(optionsLogo);
            this.$validator.validate('companyLogo', '');

            // regions
            this.$validator.extend('companyRegions', {
                getMessage: () => 'At least one option is required',
                validate: function(value){
                    return value.findAll({ state: { checked:true }}).length > 0;
                }
            });
            const optionsRegions = {
                name:'companyRegions',
                rules: 'companyRegions',
                getter:function() {
                    return self.$refs.tree2;
                }
            };    
            this.$validator.attach(optionsRegions);
            this.$validator.validate('companyRegions', '');

            // wordLength
            this.$validator.extend('wordLength', {
                getMessage: (value, limit) => 'Field limited to '+limit+' words',
                validate: function(value, limit){
                    if( (value.match(/\S+/g)) != null ) {
                        return value.match(/\S+/g).length < limit[0];
                    }
                }
            });
            const optionsWordLength = {
                name:'wordLength',
                rules: 'wordLength'
            };    
            this.$validator.attach(optionsWordLength);
            this.$validator.validate('wordLength', '');


        },
        beforeMount() {
            this.employeeOptions = this.configEmployeeOptions();
            this.siteKey         = this.configSitekey();
            this.regionTreeData  = this.configRegionsTreeData();
        },
        watch: {
            "formFields": { // watches for changes to all form fields, and then runs validation on them
                handler: function(){
                    var self = this;
                    this.$validator.validate().then(result => {
                        self.formValid = result;
                    });
                },
                deep: true
            },
        }
    }
</script>
